import { useEffect, useRef } from "react";
import logo from '../img/logos/logosymbol.png';
import "./loading.css"



export const Loading = () => {
    const logoRef = useRef(null);
    const waveRef = useRef(null);

    useEffect(() => {
        const logo = logoRef.current;
        const wave = waveRef.current;

        const handleAnimationIteration = () => {
            wave.style.animation = "none";
            setTimeout(() => {
                wave.style.animation = "";
            }, 10); // petite pause pour redémarrer l'animation
        };

        logo.addEventListener("animationiteration", handleAnimationIteration);

        return () => {
            logo.removeEventListener("animationiteration", handleAnimationIteration);
        };
    }, []);

    return (
        <div className="loading-body">
            <div className="loading-container">
                <div className="wave" ref={waveRef}></div>
                <img src={logo} alt="Logo" className="logo" ref={logoRef} />
            </div>
        </div>
    );
}