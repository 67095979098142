import { useTranslation } from 'react-i18next';

export const TermsService = () => {

    const { t } = useTranslation();

    return (
    <>
        <section className="mt-5 pt-50 text-xs-center animated fadeIn delay-05">
            <div className="container-1">
                <div className="row align-items-center text-center">
                <div className="col-xs-6 col-md-12 col-xl-6 offset-xl-3">
                    <h1 className="title-1 mb-10">{t("terms.title")}</h1>
                    <div className="align-items-center mb-10">
                    </div>            
                </div>
                </div>
            </div>
        </section>
        
        <section className="section-social animated fadeIn delay-05">
            <div className="container-1">
            <div className="row">
            <div className="col-xs-12 col-xl-12">
                <h3 className="title-3 mb-30">{t("terms.intro")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art1")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content1")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art2")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content2")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art3")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content3")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art4")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content4")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art5")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content5")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art6")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content6")}
               </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art7")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content7")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art8")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content8")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art9")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content9")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art10")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content10")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art11")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content11")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art12")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content12")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art13")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content13")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art14")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content14")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art15")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content15")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art16")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content16")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art17")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content17")}
                </p>
                <h3 className="title-3 mb-30">
                    {t("terms.art18")}
                </h3>
                <p className="paragraph mb-50">
                    {t("terms.content18")}
                </p>
            </div>
            </div>
            <div className="row">
            <div className="col-xs-12 col-xl-8  offset-xl-2">
                <p className="paragraph">
                </p>
            </div>
            </div>
            </div>
        </section>

    </>
    )
}