import { useTranslation } from 'react-i18next';

export const PrivacyPolicy = () => {

    const { t } = useTranslation();

    return ( 
    <>
        <section className="mt-5 pt-50 text-xs-center animated fadeIn delay-05">
            <div className="container-1">
                <div className="row align-items-center text-center">
                <div className="col-xs-6 col-md-12 col-xl-6 offset-xl-3">
                    <h1 className="title-1 mb-10">{t("privacy.title")}</h1>
                    <div className="align-items-center mb-5">
                    </div>            
                </div>
                </div>
            </div>
        </section>
        <section className="section-social animated fadeIn delay-05">
            <div className="container-1">
                <div className="row">
                <div className="col-xs-11 col-xl-12">
                    <p className="paragraph">
                        {t("privacy.main")} <br/>
                        {t("privacy.second")}
                    </p>
                </div>
                </div>
                <div className="row">
                <div className="col-xs-12 col-xl-8  offset-xl-2">
                    <p className="paragraph">
                    </p>
                </div>
                </div>
            </div>
        </section>

    </>
    )
}