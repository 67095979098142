import { useTranslation } from "react-i18next";
import logoAvailabs from "../img/logos/logo-purple.png";
import logoAvailabsBlue from "../img/logos/logo-availabs-blue.png";
import { Link, animateScroll as scroll } from "react-scroll";


export const Navbar = () => {

    return (
        <div>
            
        {/*Navbar Desktop*/}
        <nav className="fixed-top d-none d-md-block">
          <div className="nav-container">
            <div className="navbarz row align-items-center">
              <div className="col-auto">
                <Link style={{ cursor: 'pointer' }}   className="navbar-brand" to="home"><img className="logo-purple small" src={logoAvailabs} alt="" /></Link>
              </div>
              <div className="col-auto text-center   align-items-center mx-auto">
                <Link style={{ cursor: 'pointer' }} className="nav-link-desktop " to="solutions">Solutions</Link>
                <Link style={{ cursor: 'pointer' }} className="nav-link-desktop" to="blue-glow-section">Finances</Link>
                <Link style={{ cursor: 'pointer' }} className="nav-link-desktop" to="green-glow-section">Incubation</Link>
                <Link  style={{ cursor: 'pointer' }}className="nav-link-desktop" to="projects">Projets</Link>
                <Link  style={{ cursor: 'pointer' }}className="nav-link-desktop" to="team">L'équipe</Link>
              </div>
              <div className="col-auto">
                {/* Button trigger modal */}
                <a href="https://tally.so/r/w7KKbP" target="_blank" className="btn-multi-nav">Prendre contact</a>
              </div>
            </div>
          </div>
        </nav>
        {/*Navbar Mobile*/}
        <nav className=" navbarz-mobile fixed-top d-block d-md-none">
          <div className="nav-container">
            <div className="row align-items-center justify-content-between mx-auto my-auto">
              <div className="col-auto">
                <Link className="navbar-brand" to="#"><img className="logo-purple small" src={logoAvailabs} alt="" /></Link>
              </div>
              <div className="col-auto">
                <a data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={32} height={32} fill="rgba(255,255,255,1)"><path d="M16 18V20H5V18H16ZM21 11V13H3V11H21ZM19 4V6H8V4H19Z" /></svg>
                </a>
                <div className="nav-mobile offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                  <div className="offcanvas-header mb-40" style={{marginTop: '10px'}}>
                    <img src={logoAvailabsBlue} className="img-fluid" style={{width: '170px', height: 'auto'}} alt="" />
                    <button type="button" className="btn-close text-reset white" data-bs-dismiss="offcanvas" aria-label="Close" />
                  </div>
                  <div className="offcanvas-body d-flex flex-column justify-content-between" style={{height: '100%'}}>
                    <div>
                      <Link className="nav-link-mobile" to='solutions'>Solutions</Link>
                      <Link className="nav-link-mobile" to='blue-glow-section'>Finances</Link>
                      <Link className="nav-link-mobile" to='green-glow-section"'>Incubation</Link>
                      
                      <Link className="nav-link-mobile" to='projects'>Projets</Link>
                      <Link className="nav-link-mobile mb-20" to='team'>L'équipe</Link>
                      <Link className="nav-link-mobile mb-20" to=''>Twitter</Link>
                      <Link className="nav-link-mobile mb-20" to=''>Linkedin</Link>
                    </div>
                    <div>
                      <a href="https://tally.so/r/w7KKbP" target="_blank" className="btn-multi">Prendre contact</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
}