import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Footer } from "./components/footer";
import { Home } from "./pages/home";
import { PrivacyPolicy } from "./pages/privacyPolicy";
import { TermsService } from "./pages/termsOfService";
import { useEffect, useState } from "react";
import { Loading } from "./components/loading";


function App() {

  const [loading, setLoading] = useState(true);

  // This will run one time after the component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);

  }, []);


  const WhenReady = ({ children }: { children: React.ReactNode }) => {
    if (loading) return <Loading />;
    return <>{children}</>;
  }

  return (
    <div className="App">
      <WhenReady>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsService />} />
            <Route
              path="*"
              element={<Navigate to="/" replace />}
            />
          </Routes>
        </BrowserRouter>
        <Footer />
      </WhenReady>
    </div>
  );
}

export default App;
