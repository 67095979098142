import { useTranslation } from 'react-i18next';
import logoLong from '../img/logos/logo-purple.png';
import { Link } from 'react-scroll';


export const Footer = () => {

    const { t } = useTranslation();


    return (
        <>
                 <footer data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay={300} data-aos-offset={0}>
        <div className="section-container footer align">
          <div className="row align-items-center">
            <div className="col-md-6 text-start">
              <img className="mb-10" src={logoLong} alt="" style={{width: '100px', height: 'auto'}} />
              <p className="subtitle mb-30" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={200}>
                Unlock Potential
              </p>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <Link  style={{ cursor: 'pointer' }}  className="nav-link white d-block d-md-inline mb-2 mb-md-0"  to="solutions">Solutions</Link>
              <Link  style={{ cursor: 'pointer' }}  className="nav-link white d-block d-md-inline mb-2 mb-md-0" to="blue-glow-section">Finances</Link>
              <Link  style={{ cursor: 'pointer' }}  className="nav-link white d-block d-md-inline mb-2 mb-md-0"  to="green-glow-section">Incubation</Link>
              <Link  style={{ cursor: 'pointer' }}   className="nav-link white d-block d-md-inline mb-2 mb-md-0"  to="projects">Projets</Link>
              <Link   style={{ cursor: 'pointer' }}  className="nav-link white d-block d-md-inline mb-2 mb-md-0"  to="team">L'équipe</Link>
            </div>
          </div>
          <hr style={{borderColor: 'white', color: 'white'}} />
          <div className="row">
            <div className="col-md-6 text-start">
              <p className="subtitle white-50">©2023 Availabs - All rights reserved</p>
            </div>
            <div className="col-md-6 text-start text-md-end">
              <Link  style={{ cursor: 'pointer' }}  className="nav-link white d-block d-md-inline mb-2 mb-md-0" to="/privacy">Privacy Policies</Link>
              <Link  style={{ cursor: 'pointer' }}  className="nav-link white d-block d-md-inline mb-2 mb-md-0" to="/terms">Terms &amp; Conditions</Link>
            </div>
          </div>
        </div>
      </footer>

        </>
    )
}